import { setLocale } from "@vee-validate/i18n";
import { acceptHMRUpdate, defineStore } from "pinia";
import type { ConfigModalitaPagamentoCliente } from "../models/customer/configModalitaPagamentoCliente";
import type { ConfigurazioneGeneraleCliente } from "../models/customer/configurazioneGeneraleCliente";
import type { ConfigurazionePersonalizzataCliente } from "../models/customer/configurazionePersonalizzataCliente";
import type { Favorite } from "../models/customer/favoriteAddress";
import type {
  CustomerTripSummary,
  ValuteListiniKeys,
  Viaggio,
} from "../models/trip/trip";
import type {
  RegistrationRequest,
  UserInfo,
  Registration,
} from "../models/user/user";
import CurrencyApi from "../services/CurrencyApi";
import CustomerApi from "../services/CustomerApi";
import PaymentApi from "../services/PaymentApi";
import PromoApi from "../services/PromoApi";
import UserApi from "../services/UserApi";
import { getBrowserLocale, getLocaleOnly } from "../utils/i18n";
import { isWorkRide } from "../utils/isWorkRide";
import { isValidPhoneWithPrefix } from "../utils/validators";
import { useTripStore } from "./trip";
import { datadogRum } from "@datadog/browser-rum";
import { LANG_ISO_CODE } from "../constants/lang_iso_code";
import type { AgencyBranding } from "../models/company/agencyBranding";
import CompanyApi from "../services/CompanyApi";
import type { CurrencyExchange } from "../models/currency/currencyExchange";
import type { Owner } from "../models/customer/owners";

type UserStore = {
  accessToken: string;
  registration: Registration | null;
  userInfo: UserInfo | null;
  userApi: UserApi;
  credito: number;
  promoApi: PromoApi;
  customerApi: CustomerApi;
  customerConfigs: Array<ConfigurazioneGeneraleCliente>; // config for every master (eg master[0] -> customerConfigs[0])
  paymentApi: PaymentApi;
  favoriteAddresses: Array<Favorite>;
  currencyApi: CurrencyApi;
  eurExchangeRate: {
    [key: string]: number;
  };
  customCustomerConfig: Array<ConfigurazionePersonalizzataCliente>;
  masterCode: null | string;
  masterCodeCustomerConfig: null | ConfigurazioneGeneraleCliente;
  agency: null | AgencyBranding;
  companyApi: CompanyApi;
  currencies: CurrencyExchange[];
  owner: null | Owner;
  masterCodeCheked: boolean;
  favoriteLanguage: string; //ISO string
};

export const useUserStore = defineStore("user", {
  state: (): UserStore => ({
    accessToken: "",
    userInfo: null,
    registration: null,
    userApi: new UserApi(),
    credito: 0,
    promoApi: new PromoApi(),
    customerApi: new CustomerApi(),
    customerConfigs: new Array<ConfigurazioneGeneraleCliente>(),
    paymentApi: new PaymentApi(),
    favoriteAddresses: new Array<Favorite>(),
    currencyApi: new CurrencyApi(),
    eurExchangeRate: {
      GBP: 1.17,
      CHF: 1,
      EUR: 1,
    },
    customCustomerConfig: new Array<ConfigurazionePersonalizzataCliente>(),
    masterCode: null,
    masterCodeCustomerConfig: null,
    agency: null,
    companyApi: new CompanyApi(),
    currencies: [] as CurrencyExchange[],
    owner: null,
    masterCodeCheked: false,
    favoriteLanguage: "en-GB",
  }),
  getters: {
    isPrivate: (state) => state.userInfo?.master?.length === 0,
    isSlave: (state) =>
      state.userInfo &&
      state.userInfo?.master?.length > 0 &&
      state.userInfo?.payload?.idCliente !== state.userInfo?.master[0].id,
    isMaster: (state) =>
      state.userInfo &&
      state.userInfo?.master?.length > 0 &&
      state.userInfo?.payload?.idCliente === state.userInfo?.master[0].id,
    isCompany: (state) =>
      (state.userInfo?.master && state.userInfo?.master?.length > 0) || false,
    favoriteCurrency: (state) => state.customerConfigs[0]?.favCurrency || 1,
    canSeePrices(): boolean {
      if ((this.isMaster && !this.isSlave) || this.isPrivate) {
        return true;
      }
      const canSeePrices = this.customerConfigs.find(
        (c) => c.iD_Cliente === this.userInfo?.payload?.idCliente,
      )?.visualizzaPrezzoSlave;
      return canSeePrices === true || canSeePrices === undefined;
    },
    currentIdCliente: (state) => state.userInfo?.payload?.idCliente,
    currentUserCurrency: (state) => state.customerConfigs[0]?.favCurrency || 1,
    currentCustomerConfig: (state) =>
      state.customerConfigs.find(
        (c) => c.iD_Cliente === state.userInfo?.payload?.idCliente,
      ) || ({} as ConfigurazioneGeneraleCliente),
    currentIdAzienda: (state) => state.userInfo?.payload.iD_Azienda,
    masterConfig: (state) =>
      state.customerConfigs.length > 1
        ? state.customerConfigs.find(
            (c) => c.iD_Cliente === state.userInfo?.master[0].id,
          )
        : state.customerConfigs[0],
    currentLoyaltyProgram(): "flyingBlue" | "milleMiglia" | "volare" | null {
      if (!this.currentCustomerConfig) {
        return null;
      }
      if (this.currentCustomerConfig.flyingBlueCode) {
        return "flyingBlue";
      }
      if (this.currentCustomerConfig.milleMigliaCode) {
        return "milleMiglia";
      }
      if (this.currentCustomerConfig.volareMembershipNumber) {
        return "volare";
      }
      return null;
    },
    isHotel: (state) => state.userInfo?.isHotel || false,
    isAgency: (state) =>
      state.customerConfigs[0]?.isAgenzia || state.agency != null || false,
    ownerCurrency: (state) =>
      state.currencies.find((e) => e.id === state.owner?.currencyId)?.id || 1,
  },
  actions: {
    setToken(token: string) {
      this.accessToken = token;
      localStorage.setItem("accessToken", token);
    },
    getToken() {
      if (!this.accessToken) {
        this.accessToken = localStorage.getItem("accessToken") || "";
      }
      return this.accessToken;
    },
    isPending(accessToken: string) {
      if (accessToken.startsWith("pending")) {
        return true;
      }
      return false;
    },

    async validateSession() {
      const tripStore = useTripStore();
      if (!this.getToken()) {
        return false;
      }
      this.masterCode = localStorage.getItem("masterCode");

      if (this.masterCode) {
        await this.getAgencyInfo(this.masterCode);
      }

      if (this.userInfo && !this.isPending(this.getToken())) {
        return true;
      }
      this.uiStore.toggleLoading(true);
      try {
        const { accessToken, user } = await this.userApi.validateSession();
        this.userInfo = user;

        if (
          this.userInfo.master.length > 0 &&
          this.userInfo.master[0].isAgency
        ) {
          this.agency = {
            logo_intestazione: this.userInfo.master[0].logo,
            ragioneSociale: this.userInfo.master[0].ragioneSociale,
          };
        }

        if (this.isPending(accessToken)) {
          return false;
        }
        this.setToken(accessToken);
        await Promise.all([
          this.getUserConfig(),
          this.getUserCredit(),
          this.getFavoriteAddresses(),
          this.getCustomerCustomConfig(),
          // this.getEurExchanges(),
          this.getAllCurrencies(),
        ]);
        tripStore.setupPrivateOrCompanyTrip();

        const customerConfigLang: keyof typeof LANG_ISO_CODE | null =
          this.currentCustomerConfig.notificationLanguage &&
          this.currentCustomerConfig.notificationLanguage.replace(/ /g, "") !==
            ""
            ? (this.currentCustomerConfig
                .notificationLanguage as keyof typeof LANG_ISO_CODE)
            : null;
        const customerLang = customerConfigLang
          ? LANG_ISO_CODE[customerConfigLang]
          : null;

        if (customerLang !== null) {
          localStorage.setItem("favorite_language", customerLang);
          this.favoriteLanguage = customerLang;
          (this.i18n.global.locale as any) = customerLang;
          setLocale(customerLang);
        } else if (!customerLang) {
          this.currentCustomerConfig.notificationLanguage = getLocaleOnly(
            getBrowserLocale(),
          );
        }

        datadogRum.setUser({
          email: user.email,
          id: user.payload.idCliente.toString(),
          name: user.payload.ragioneSociale,
        });

        /**
         * load google apis only when user is logged in
         */
        const oHead = document.getElementsByTagName("head")[0];

        const encodedKeys = await tripStore.tripApi.getApiKeys(
          this.currentIdAzienda ?? 1,
        );
        const keys = encodedKeys.split("|");
        const randIdx = Math.floor(Math.random() * keys.length);
        const mapsScriptUrl =
          `https://maps.googleapis.com/maps/api/js?&callback=initMap&key=${keys[randIdx]}&libraries=places&language=` +
          localStorage.getItem("lang");

        const js = document.createElement("script");
        js.setAttribute("language", "javascript");
        js.type = "text/javascript";
        js.src = mapsScriptUrl;
        js.async = true;
        const jsCallback = () =>
          new Promise((res, rej) => {
            js.onload = () => res(true);
            js.onerror = () => rej("Cannot load gmaps");
          });
        oHead.appendChild(js);
        await jsCallback();

        datadogRum.addAction(`using this google api key ${keys[0]}`);

        return true;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        if (
          (e as Error).message !==
            "NetworkError when attempting to fetch resource." &&
          e instanceof TypeError == false
        ) {
          this.logout();
        }
        return false;
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    clearLocalStorage() {
      const newClientBanner = localStorage.getItem("new_client");
      localStorage.clear();
      localStorage.setItem("new_client", newClientBanner as string);
    },

    async logout() {
      this.clearLocalStorage();
      datadogRum.stopSession();
      window.location.href = "/access"; //force navigation reset
    },

    async login(email?: string, phone?: string) {
      this.uiStore.toggleLoading(true);
      try {
        const user = await this.userApi.login(email, phone);
        this.registration = user;
        this.router.push("/access/otp");
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async validateOTP(otp: string) {
      this.uiStore.toggleLoading(true);
      try {
        const { accessToken, user } = await this.userApi.validateOTP(
          this.registration!.email,
          this.registration!.phone,
          otp,
        );
        this.setToken(accessToken);
        this.registration = user;
        if (this.isPending(accessToken)) {
          this.router.push("/access/register");
          return;
        }
        this.router.push("/");
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async signup(email?: string, prefix?: string, phone?: string) {
      this.uiStore.toggleLoading(true);
      try {
        const user = await this.userApi.signup(email, prefix, phone);
        this.registration = user;
        this.router.push("/access/otp");
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async finalize(
      user: RegistrationRequest,
      isEmployee: boolean,
      idazienda = 1,
      os = "web",
    ) {
      this.uiStore.toggleLoading(true);
      try {
        const phone = user.prefix + user.phone;
        if (!isValidPhoneWithPrefix(phone)) {
          throw new Error("Missing phone prefix or invalid phone");
        }
        user.phone = phone;
        const response = await this.userApi.finalize(user, idazienda, os);
        this.setToken(response.accessToken);
        if (!isEmployee) {
          window.location.href = "/";
          return;
        }
        const res = await this.userApi.validateSession();
        this.userInfo = res.user;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async resendOTP() {
      this.uiStore.toggleLoading(true);
      try {
        const user = await this.userApi.resendOTP(
          this.registration?.email,
          this.registration?.phone,
        );
        this.registration = user;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    /**
     *
     * @param compoanyId id azienda
     * @param companyName ragione sociale
     * @param companyVatCode
     */
    async askCompanyAssociation(
      compoanyId: number,
      companyName: string,
      companyVatCode: string,
    ) {
      this.uiStore.toggleLoading(true);
      try {
        await this.userApi.askCompanyAssociation(
          compoanyId,
          companyName,
          companyVatCode,
          this.userInfo!.email,
        );
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async companyAssociationWithCode(code: string) {
      this.uiStore.toggleLoading(true);
      try {
        await this.companyApi.masterCodeExists({
          masterCode: code,
        });
        await this.userApi.companyAssociationWithCode(code);
        return true;
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          this.toast.error(this.i18n.global.t((e as Error).message));
        } else {
          this.toast.error(this.i18n.global.t("Cannot_associate"));
        }
        return false;
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async finalizeCompany(
      user: RegistrationRequest,
      idazienda = 1,
      os = "web",
    ) {
      this.uiStore.toggleLoading(true);
      try {
        const phone = user.prefix + user.phone;
        if (!isValidPhoneWithPrefix(phone)) {
          throw new Error(this.i18n.global.t("Error_invalid_phone_number"));
        }

        const splitted = user.ragioneSociale.split(" ");
        const name = splitted[0];
        let surname = splitted.slice(1).join(" ");
        if (!surname || surname === "") {
          surname = name;
        }
        user.name = name;
        user.surname = surname;
        const response = await this.userApi.finalizeCompany(
          { ...user, phone },
          idazienda,
          os,
        );
        // this.userInfo = response.user;
        this.setToken(response.accessToken);
        window.location.href = "/";
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getCustomerPaymentMethods(
      customerId: number,
      idAzienda: number,
      personalTrip = false,
    ) {
      this.uiStore.toggleLoading(true);
      let methods = new Array<ConfigModalitaPagamentoCliente>();
      try {
        methods = await this.customerApi.getCustomerPaymentMethods(
          this.currentIdCliente!,
          idAzienda,
          personalTrip,
        );
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
      return methods;
    },

    async getUserCredit() {
      this.uiStore.toggleLoading(true);
      try {
        const data = await this.promoApi.getCustomerCredit();
        this.credito = data.ImportoFavorite ?? data.Importo ?? 0;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getUserConfig() {
      this.uiStore.toggleLoading(true);
      try {
        if (this.isPrivate) {
          this.customerConfigs = [
            await this.customerApi.getCustomerConfiguration(
              this.userInfo!.payload.idCliente,
              this.userInfo!.payload.iD_Azienda,
            ),
          ];
        } else {
          if (!this.isSlave) {
            const promises = this.userInfo!.master.map((master) =>
              this.customerApi.getCustomerConfiguration(
                master.id,
                this.userInfo!.payload.iD_Azienda,
              ),
            );
            const results = await Promise.allSettled(promises);
            this.customerConfigs = results
              .filter((res) => res.status === "fulfilled")
              .map(
                (res) =>
                  (res as PromiseFulfilledResult<ConfigurazioneGeneraleCliente>)
                    .value,
              );
          } else {
            this.customerConfigs[0] =
              await this.customerApi.getCustomerConfiguration(
                this.userInfo!.payload.idCliente,
                this.userInfo!.payload.iD_Azienda,
              );
          }
        }

        this.owner = await this.customerApi.getOwnerById(
          this.currentCustomerConfig.idOwner ?? 1,
          this.currentCustomerConfig.iD_Azienda ?? 1,
        );
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getCustomerCustomConfig() {
      this.uiStore.toggleLoading(true);
      try {
        this.customCustomerConfig =
          await this.customerApi.getCustomerCustomConfiguration(
            this.currentIdCliente!,
            this.currentIdAzienda!,
          );
        const tripStore = useTripStore();
        await tripStore.loadPricingList();
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getFavoriteAddresses() {
      try {
        this.uiStore.toggleLoading(true);
        this.favoriteAddresses = await this.customerApi.getFavoriteAddresses(
          this.userInfo!.payload.idCliente,
          this.userInfo!.payload.iD_Azienda,
        );
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async deleteFavoriteAddress(address: Favorite) {
      try {
        this.uiStore.toggleLoading(true);
        await this.customerApi.deleteFavoriteAddress(
          address.id,
          address.iDAzienda || 1,
        );
        this.favoriteAddresses = this.favoriteAddresses.filter(
          (f) => f.id !== address.id,
        );
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async addFavoriteAddress(address: Partial<Favorite>) {
      try {
        this.uiStore.toggleLoading(true);
        address.id = await this.customerApi.insertFavoriteAddress(
          address as Favorite,
          this.userInfo!.payload.iD_Azienda,
        );
        this.favoriteAddresses.push(address as Favorite);
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getAllPaymentMethods() {
      this.uiStore.toggleLoading(true);
      try {
        return this.customerApi.getAllPaymentMethods();
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        return [];
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getEurExchanges() {
      try {
        this.uiStore.toggleLoading(true);
        const rates = await this.currencyApi.getExchangeRates();
        this.eurExchangeRate = rates.reduce((eurExchangeRate, rate) => {
          return {
            ...eurExchangeRate,
            [rate.symbol]: rate.fromEuro,
          };
        }, {} as { [key: string]: number });
      } catch (e) {
        console.error(
          "Could not retrieve exchange rates due to (falling back to defaults): ",
          e,
        );
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    setMasterCode(masterCode: string) {
      this.masterCode = masterCode.trim();
      localStorage.setItem("masterCode", this.masterCode);
    },

    canSeeFullPrice(ride?: CustomerTripSummary | Viaggio) {
      // return (
      //   this.userInfo?.payload.prezzoTotaleSlaves ||
      //   this.isPrivate ||
      //   (ride && !isWorkRide(ride))
      // );
      return true;
    },

    async getAgencyInfo(masterCode: string) {
      if (
        (this.accessToken && !this.isPending(this.accessToken)) ||
        this.agency != null
      ) {
        return;
      }
      try {
        this.uiStore.toggleLoading(true);
        this.agency = await this.companyApi.find({ masterCode });
      } catch (e) {
        console.error("Error_cannot_load_agency_info", e);
        if (!this.masterCodeCheked && e instanceof Error) {
          this.toast.error(this.i18n.global.t((e as Error).message));
        }
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    getMasterCode() {
      return this.masterCode || localStorage.getItem("masterCode");
    },

    async getAllCurrencies() {
      try {
        this.uiStore.toggleLoading(true);
        this.currencies = await this.currencyApi.getAllCurrencies();
      } catch (e) {
        console.error("Could not retrieve all currencies: ", e);
        if (e instanceof Error) {
          this.toast.error(this.i18n.global.t((e as Error).message));
        }
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    clearMasterCode() {
      this.masterCode = null;
      localStorage.removeItem("masterCode");
    },

    async masterCodeExists(code?: string) {
      if (!code && (!this.getMasterCode() || this.masterCodeCheked)) {
        return;
      }
      try {
        this.uiStore.toggleLoading(true);
        await this.companyApi.masterCodeExists({
          masterCode: code ?? this.getMasterCode()!,
        });
        this.masterCodeCheked = true;
      } catch (e) {
        this.clearMasterCode();
        if (e instanceof Error) {
          this.toast.error(this.i18n.global.t((e as Error).message));
        }
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot)); // allows hot reloading of the store
}
