import { defineRule, configure } from "vee-validate";
import countries_prefixes from "../constants/countries_prefixes";
import { localize } from "@vee-validate/i18n";
import en from "@vee-validate/i18n/dist/locale/en.json";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
import it from "@vee-validate/i18n/dist/locale/it.json";
import { useTripStore } from "../stores/trip";

configure({
  generateMessage: localize({
    "en-GB": en,
    "en-US": en,
    "fr-FR": fr,
    "it-IT": it,
  }),
});

export function isValidPhoneWithPrefix(phone: string) {
  if (!phone || /^\+?([0-9]\s?)+$/.test(phone.replace(/ /g, "")) === false) {
    return false;
  }
  return true;
}

export function isValidEmail(email: string) {
  // eslint-disable-next-line no-useless-escape
  if (!email || !email.includes("@") || !email.includes(".")) {
    return false;
  }
  return true;
}

export function isValidName(name: string) {
  return name && /^.+$/.test(name);
}

export function isValidSurname(surname: string) {
  return surname && /^.+$/.test(surname);
}

export function isValidVATcode(vat: string) {
  if (/^\d{11}$/.test(vat) === false) {
    return false;
  }
  return true;
}

export function isValidFiscalCode(fiscalCode: string) {
  if (/^[a-zA-Z0-9]{16}$/.test(fiscalCode) === false) {
    return false;
  }
  return true;
}

export function isValidBusinessName(name: string) {
  if (/^[a-zA-Z0-9\s]+$/.test(name) === false) {
    return false;
  }
  return true;
}

export function isValidPECMail(PEC: string) {
  // eslint-disable-next-line no-useless-escape
  if (/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(PEC) === false) {
    return false;
  }
  return true;
}

export function isValidDestinationCode(code: string) {
  if (/^[a-zA-Z0-9]+$/.test(code) === false) {
    return false;
  }
  return true;
}

export function isValidCity(city: string) {
  if (/^[a-zA-Z\s]+$/.test(city) === false) {
    return false;
  }
  return true;
}

export function isValidAddress(address: string) {
  // eslint-disable-next-line no-useless-escape
  if (/^[a-zA-Z0-9\s,\/-]+$/.test(address) === false) {
    return false;
  }
  return true;
}

export function isValidCAP(CAP: string) {
  if (/^\d{1,10}$/.test(CAP) === false) {
    return false;
  }
  return true;
}

export function isValidPrefix(prefix: string) {
  return countries_prefixes.find((p) => p.dial_code === prefix) !== undefined;
}

export function isValidPhoneWithoutPrefix(phone: string) {
  if (/^[0-9]{9,10}$/.test(phone.replace(/ /g, "")) === false) {
    return false;
  }
  return true;
}

defineRule("required", (value: string | number) => {
  if (!value || (typeof value === "string" && !value.length)) {
    return false;
  }
  return true;
});
defineRule("email", isValidEmail);
defineRule(
  "phoneWithPrefix",
  (phone: string, [prefix]: [string]) =>
    isValidPhoneWithPrefix(phone) || isValidPhoneWithPrefix(prefix + phone),
);
defineRule("name", isValidName);
defineRule("surname", isValidSurname);
defineRule("vatCode", isValidVATcode);
defineRule("fiscalCode", isValidFiscalCode);
defineRule("businessName", isValidBusinessName);
defineRule("pecMail", isValidPECMail);
defineRule("destinationCode", isValidDestinationCode);
defineRule("city", isValidCity);
defineRule("address", isValidAddress);
defineRule("cap", isValidCAP);
defineRule(
  "optionalPhoneWithPrefix",
  (phone: string, [prefix, shouldSave]: [string, boolean]) => {
    if (shouldSave) {
      return isValidPhoneWithPrefix(prefix + phone);
    }
    return (
      (!phone && !prefix) ||
      (prefix && isValidPhoneWithPrefix(prefix + phone)) ||
      (!prefix && isValidPhoneWithPrefix(phone))
    );
  },
);
defineRule("optionalEmail", (value: string) => !value || isValidEmail(value));
defineRule("emailWhenShouldSavePassenger", (value: string, params, ctx) => {
  const regex = /(\d+)/g;
  const matches = ctx.name.match(regex);
  if (!matches) {
    return false;
  }
  const passengerIdx = parseInt(matches[0]);
  if (
    (ctx.form as any).passengers[passengerIdx].shouldSave &&
    !isValidEmail(value)
  ) {
    return false;
  }
  return true;
});
defineRule("max", (value: string, [max]: [number]) => {
  return !value || value?.length <= max;
});
defineRule(
  "optionalPrefix",
  (prefix: string, [phone]: [string]) =>
    (!prefix && !phone) ||
    countries_prefixes.find((p) => p.dial_code === prefix) !== undefined,
);
defineRule(
  "prefix",
  (prefix: string) =>
    countries_prefixes.find((p) => p.dial_code === prefix) !== undefined,
);
defineRule("validPhoneWithoutPrefix", isValidPhoneWithoutPrefix);
defineRule("differFrom", (str1: string, str2: string) => {
  return str1 != str2;
});
defineRule("validCoordsStartAddress", (address: string) => {
  const tripStore = useTripStore();
  return (
    address?.length > 0 &&
    tripStore.tripToInsert.puntoPartenzaLong != undefined &&
    tripStore.tripToInsert.puntoPartenzaLat != undefined
  );
});
defineRule("validCoordsEndAddress", (address: string) => {
  const tripStore = useTripStore();
  return (
    address?.length > 0 &&
    tripStore.tripToInsert.puntoDestinazioneLong != undefined &&
    tripStore.tripToInsert.puntoDestinazioneLat != undefined
  );
});
