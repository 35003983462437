import { CLIENT_NAME, CLIENT_VERSION } from "../constants/utils";
import type { PagingSelectionModel } from "../models/api/pagination";
import type { GoogleRoute } from "../models/trip/route";
import type {
  PreventivoModel,
  CalculateCostRequest,
  MinutiCliente,
  Point,
  ViaggiPasseggeri,
  Viaggio,
  ViaggiPasseggeriWithPasseggero,
  CustomerTripSummary,
  CentroNotifiche,
  MinutiClienteV2,
} from "../models/trip/trip";
import Api, { parseApiResponse } from "./Api";
import { UploadFileAsync, UploadMultiFilesAsync } from "../utils/fileUtil";
import type { AllegatiViaggio } from "@/DTO/AllegatiDTO";
export default class TripApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL;
  v2BaseApiUrl = this.baseApiUrl + "v2/viaggi/";
  v1BaseApiUrl = this.baseApiUrl + "viaggi/";
  oldWebserviceApiUrl = import.meta.env.VITE_WS_URL;

  async getMinutesCliente(
    idCliente: number,
    latitude: number,
    longitude: number,
    idazienda = 1,
    os = "web",
  ) {
    const response = await this.post(
      this.v2BaseApiUrl,
      `getminutescliente?idazienda=${idazienda}`,
      {
        idCliente,
        idAzienda: idazienda,
        latitude,
        longitude,
        os,
      },
    );
    const stringPayload = await parseApiResponse<string>(response);
    return JSON.parse(stringPayload) as MinutiCliente;
  }

  async calculateTrip(data: CalculateCostRequest) {
    data.os = "web";
    const stringPayload = await parseApiResponse<string>(
      await this.post(
        this.v1BaseApiUrl,
        `calculateviaggio?idazienda=${data.aziendaid}`,
        data,
      ),
    );
    return JSON.parse(stringPayload) as Array<PreventivoModel>;
  }

  async discoverRoute(from: Point, to: Point, date: string, idazienda = 1) {
    return parseApiResponse<GoogleRoute>(
      await this.post(
        this.v2BaseApiUrl,
        `route/discover?idazienda=${idazienda}`,
        {
          from,
          to,
          date,
        },
      ),
    );
  }

  async createTripPassenger(tripPassenger: ViaggiPasseggeri, idazienda = 1) {
    return parseApiResponse<ViaggiPasseggeri>(
      await this.post(
        this.v2BaseApiUrl,
        `passengers?idazienda=${idazienda}`,
        tripPassenger,
      ),
    );
  }

  async deleteTripPassenger(tripPassengerId: number, idazienda = 1) {
    return parseApiResponse<ViaggiPasseggeri>(
      await this.delete(
        this.v2BaseApiUrl,
        `passengers/${tripPassengerId}?idazienda=${idazienda}`,
      ),
    );
  }

  async updateTripPassenger(tripPassenger: ViaggiPasseggeri, idazienda = 1) {
    return parseApiResponse<ViaggiPasseggeri>(
      await this.put(
        this.v2BaseApiUrl,
        `passengers?idazienda=${idazienda}`,
        tripPassenger,
      ),
    );
  }

  async getTripPassengers(tripId: number, idazienda = 1, withPassenger = true) {
    return parseApiResponse<Array<ViaggiPasseggeriWithPasseggero>>(
      await this.get(
        this.v2BaseApiUrl,
        `passengers/trip/${tripId}?idazienda=${idazienda}&withPassenger=${withPassenger}&idAziendaViaggio=${idazienda}`,
      ),
    );
  }

  async sendTrip(
    viaggio: Viaggio,
    ridePassengers: ViaggiPasseggeri[],
    rideNotifications: CentroNotifiche[],
    role = "cliente",
    idazienda = 1,
    fileAllegati: File[],
    useInternalCredit: boolean,
  ) {
    viaggio.source = CLIENT_NAME;
    viaggio.platform = "web";
    viaggio.version = CLIENT_VERSION;

    const { insertedId } = await parseApiResponse<{ insertedId: number }>(
      await this.post(
        this.v2BaseApiUrl,
        `InsertViaggio?idazienda=${idazienda}`,
        {
          values: viaggio,
          role,
          ridePassengers,
          rideNotifications,
          useInternalCredit,
        },
      ),
    );

    if (viaggio.signFile != null) {
      viaggio.pathAllegatoDescrizione = await UploadFileAsync(
        this.v2BaseApiUrl,
        `uploadcartello?idazienda=1&idviaggio=${insertedId}`,
        viaggio.signFile,
        this.getToken(),
      );
      viaggio.idViaggio = insertedId;
    }

    this.uploadFileAllegati(fileAllegati, insertedId, idazienda);

    return insertedId;
  }

  async getViaggiNonConclusiCliente(
    pagination: PagingSelectionModel,
    idazienda = 1,
  ) {
    return parseApiResponse<Array<CustomerTripSummary>>(
      await this.post(
        this.v2BaseApiUrl,
        `customer/find?idazienda=${idazienda}&ended=false`,
        pagination,
      ),
    );
  }

  async getViaggiConclusiCliente(
    pagination: PagingSelectionModel,
    idazienda = 1,
  ) {
    return parseApiResponse<Array<CustomerTripSummary>>(
      await this.post(
        this.v2BaseApiUrl,
        `customer/find?idazienda=${idazienda}&ended=true`,
        pagination,
      ),
    );
  }

  async updateTrip(
    viaggio: Viaggio,
    ridePassengers: ViaggiPasseggeri[],
    rideNotifications: CentroNotifiche[],
    role = "cliente",
    idazienda = 1,
    fileAllegati: File[],
    useInternalCredit: boolean,
  ) {
    viaggio.source = CLIENT_NAME;
    viaggio.platform = "web";
    viaggio.version = CLIENT_VERSION;
    /*
      needed because the BE wants puntoPartenza and puntoDestinazione as string
    */

    if (viaggio.signFile != null) {
      viaggio.pathAllegatoDescrizione = await UploadFileAsync(
        this.v2BaseApiUrl,
        `uploadcartello?idazienda=1&idviaggio=${viaggio.idViaggio}`,
        viaggio.signFile,
        this.getToken(),
      );
    }
    this.uploadFileAllegati(
      fileAllegati,
      viaggio.idViaggio,
      viaggio.iD_Azienda,
    );
    const toSend = {
      ...viaggio,
    };
    delete toSend.puntoPartenza;
    delete toSend.puntoDestinazione;
    return parseApiResponse<string>(
      await this.post(
        this.v2BaseApiUrl,
        `updateviaggio?idazienda=${idazienda}`,
        {
          values: toSend,
          role,
          ridePassengers,
          rideNotifications,
          useInternalCredit,
        },
      ),
    );
  }

  async getTrip(id: number) {
    return parseApiResponse<Viaggio>(
      await this.get(this.v2BaseApiUrl, `customer/ride/${id}`),
    );
  }

  async getVehicleList() {
    const res = await parseApiResponse<string>(
      await this.get(this.v1BaseApiUrl, "listamezzi"),
    );
    return JSON.parse(res) as Array<PreventivoModel>;
  }

  async deleteTrip(trip: Viaggio) {
    /*
         needed because the BE wants puntoPartenza and puntoDestinazione as string
        */
    const toSend = {
      ...trip,
    };
    delete toSend.puntoPartenza;
    delete toSend.puntoDestinazione;

    return parseApiResponse<string>(
      await this.post(
        this.v2BaseApiUrl,
        `CancellaViaggioCliente?idazienda=${trip.iD_Azienda}`,
        toSend,
      ),
    );
  }

  async setTripRating(
    rideId: number,
    vote: number,
    comment: string,
    companyId = 1,
  ) {
    return parseApiResponse<boolean>(
      await this.post(this.v2BaseApiUrl, `rating?idazienda=${companyId}`, {
        rideId,
        vote,
        comment,
      }),
    );
  }

  async getTripRating(rideId: number, companyId = 1) {
    return parseApiResponse<{
      rideId: number;
      vote: number;
      comment: string;
    }>(
      await this.get(
        this.v2BaseApiUrl,
        `rating/${rideId}?idazienda=${companyId}`,
      ),
    );
  }

  async getICS(rideId: number) {
    const response = await this.get(this.v2BaseApiUrl, `ics/${rideId}`);
    if (response.ok) {
      return response.blob();
    }
    return parseApiResponse<string>(response);
  }

  //Allegati
  async getFileAllegati(rideId: number, idazienda = 1) {
    const response = await this.get(
      this.v2BaseApiUrl,
      `FileAllegati?idazienda=${idazienda}&idviaggio=${rideId}&idCollaboratore=0`,
    );

    return parseApiResponse<AllegatiViaggio[]>(response);
  }

  async deleteFileAllegatoAsync(
    id: number,
    rideId: number,
    idazienda = 1,
  ): Promise<boolean> {
    let response = await this.delete(
      this.v2BaseApiUrl,
      `FileAllegati?idazienda=${idazienda}&id=${id}`,
    );

    if (response?.status === 200) {
      const resp = await response.json();
      return resp?.success;
    } else {
      return false;
    }
  }

  async uploadFileAllegati(
    fileAllegati: File[],
    rideId: number,
    idazienda = 1,
  ) {
    if (fileAllegati.length > 0) {
      let response = await UploadMultiFilesAsync(
        this.v2BaseApiUrl,
        `FileAllegati?idazienda=${idazienda}&idviaggio=${rideId}&idCollaboratore=0`,
        fileAllegati,
        this.getToken(),
      );
      return response;
    }
    return [];
  }

  async getMinReservationTime(
    idCliente: number,
    latitude: number,
    longitude: number,
    country: string,
    idazienda = 1,
    os = "web",
  ) {
    const response = await this.post(
      this.v2BaseApiUrl,
      `get-min-reservation-time?idazienda=${idazienda}`,
      {
        idCliente,
        idAzienda: idazienda,
        latitude,
        longitude,
        country,
        os,
      },
    );
    const payload = await parseApiResponse<MinutiClienteV2>(response);
    return payload;
  }

  async getApiKeys(companyId: number) {
    return parseApiResponse<string>(
      await this.get(this.v1BaseApiUrl, `getapikeys?idazienda${companyId}`),
    );
  }
}
